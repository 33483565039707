<template>
    <div id="index">
        <a-layout>
            <a-layout-header style="height:80px;background-color:#f0f2f5;position:relative;">
                <h2 class="wall-head-title">招生数据作战大屏</h2>
            </a-layout-header>
            <a-select show-search
                      placeholder="请输入机构名称"
                      style="width: 200px"
                      @change="select_handleChange"
                      @search="deploys_mechanism"
                      id="a-show-search">
                <a-select-option value="全部">
                    全部
                </a-select-option>
                <a-select-option v-for="b in mechanism" :key="b">
                    {{b}}
                </a-select-option>
            </a-select>
            <a-layout-content style="padding:0 10px;">
                <div class="index-float">
                    <a-radio-group default-value="0" button-style="solid" style="margin-right:12px" size="small" @change="radioChange">
                        <a-radio-button value="0">
                            今天
                        </a-radio-button>
                        <a-radio-button value="1">
                            昨天
                        </a-radio-button>
                        <a-radio-button value="2">
                            最近7天
                        </a-radio-button>
                        <a-radio-button value="3">
                            最近30天
                        </a-radio-button>
                    </a-radio-group>

                    <a-range-picker size="small" style="width:220px" @change="handleChange" :value="Time">
                        <template slot="dateRender" slot-scope="current">
                            <div class="ant-calendar-date" :style="getCurrentStyle(current)">
                                {{ current.date() }}
                            </div>
                        </template>
                    </a-range-picker>

                </div>
                <DataEnrollment ref="DataEnrollment" />
                <!-- 左中右 -->
                <div class="wall-bd">
                    <div class="wall-bdl" style="display:none">
                        <h3 class="wall-bdl-title" style="padding:10px 0;">推广动态</h3>
                        <!--<DataList ref="DataList" />-->
                    </div>
                    <div class="wall-bdc">
                        <DataRecruit ref="DataRecruit" />
                        <DataNewusermap ref="DataNewusermap" />
                    </div>
                    <DataRanking ref="DataRanking" />
                </div>
            </a-layout-content>
        </a-layout>
    </div>
</template>
<script>
    import "ant-design-vue/dist/antd.css";
    import 'element-ui/lib/theme-chalk/index.css';
    import "@/assets/css/yjht.css";
    import 'moment/locale/zh-cn';
    import Vue from "vue";
    import ant from "ant-design-vue";
    import ElementUI from 'element-ui';
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
    Vue.prototype.$zhCN = zh_CN;
    Vue.use(ant, ElementUI);
   // import Account from "@/Plugin/Account.js";
    import "@/assets/css/wallchart.css";
    import util from "@/Plugin/util.js";
    import DataEnrollment from "./components/DataEnrollment";
    //import DataList from "./components/DataList";
    import DataRecruit from "./components/DataRecruit";
    import DataNewusermap from "./components/DataNewusermap";
    import DataRanking from "./components/DataRanking"
    import moment from "moment";
    import Http from "../../Plugin/Http";
    export default {
        name: "",
        data() {
            return {
                date: this.GetDate(),
                Time: [],
                BeginDate: new Date(),
                EndDate: new Date(),
                fullscreen: false,
                CustomerName_Show: "",
                mechanism: [],
                CustomID: "",

            };
        },
        props: {
            //组件属性
        },
        methods: {
           
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
            //方法
            GetDate() {
                return util.formatDateTime(new Date());
            },
            getCurrentStyle(current, today) {
                console.log("型:");
                console.log(today);
                const style = {};
                if (current.date() === 1) {
                    style.border = '1px solid #1890ff';
                    style.borderRadius = '50%';
                }
                return style;
            },
            handleSizeChange(e) {
                this.size = e.target.value;
            },
            handleChange(value) {
                var self = this;
                if (value.length == 0) {
                    self.BeginDate = util.formatDate(new Date());
                    self.EndDate = util.formatDate(new Date());
                    value = [
                        moment(new Date(), "YYYY-MM-DD"),
                        moment(new Date(), "YYYY-MM-DD"),

                    ];
                    self.$message.error("必须选择日期", 1.5, function () { });
                } else {
                    self.BeginDate = util.formatDate(value[0]._d);
                    self.EndDate = util.formatDate(value[1]._d);
                }
                self.Time = value;
                console.log(value);
                self.$refs.DataEnrollment.SetDate(self.BeginDate, self.EndDate, self.CustomID);
                self.$refs.DataRecruit.SetDate(self.BeginDate, self.EndDate, self.CustomID, self.$refs.DataEnrollment.refreshVisit);
                self.$refs.DataRanking.SetDate(self.BeginDate, self.EndDate, self.CustomID,);
                self.$refs.DataNewusermap.SetDate(self.BeginDate, self.EndDate, self.CustomID)
                //self.$refs.DataList.SetDate(self.BeginDate, self.EndDate, self.CustomID);

            },
            radioChange: function (item) {
                var self = this;
                var Time = "";
                switch (item.target.value) {
                    case "0":
                        Time = [
                            moment(new Date(), "YYYY-MM-DD"),
                            moment(new Date(), "YYYY-MM-DD"),
                        ];
                        break;
                    case "1":
                        Time = [
                            moment(
                                new Date(new Date().setDate(new Date().getDate() - 1)),
                                "YYYY-MM-DD"
                            ),
                            moment(
                                new Date(new Date().setDate(new Date().getDate() - 1)),
                                "YYYY-MM-DD"
                            ),
                        ];
                        break;
                    case "2":
                        Time = [
                            moment(
                                new Date(new Date().setDate(new Date().getDate() - 7)),
                                "YYYY-MM-DD"
                            ),
                            moment(new Date(), "YYYY-MM-DD"),
                        ];
                        break;
                    case "3":
                        Time = [
                            moment(
                                new Date(new Date().setDate(new Date().getDate() - 30)),
                                "YYYY-MM-DD"
                            ),
                            moment(new Date(), "YYYY-MM-DD"),
                        ];
                        break;
                    case "4":
                        var year = new Date().getFullYear();
                        Time = [
                            moment(new Date(moment(year + "-01-01")), "YYYY-MM-DD"),
                            moment(new Date(moment(year + "-06-30")), "YYYY-MM-DD"),
                        ];
                        break;
                }
                self.handleChange(Time);
            },
            deploys_mechanism: function (v) {
                var self = this;
                console.log(v);
                var op = {
                    url: "/OperationsModule/Operations3/OPmechanism",
                    data: {
                        name: v
                    },
                    OnSuccess: function (data) {
                        var dt = data.data;
                        self.mechanism = dt;
                    }
                };
                Http.Post(op);
            },
            select_handleChange: function (v) {
                console.log(v);
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations3/OPCustomID",
                    data: {
                        Name: v,
                    },
                    OnSuccess: function (data) {
                        self.CustomID = data.data;
                        console.log(self.CustomID);
                        self.$refs.DataEnrollment.SetDate(self.BeginDate, self.EndDate, self.CustomID);
                        self.$refs.DataRecruit.SetDate(self.BeginDate, self.EndDate, self.CustomID, self.$refs.DataEnrollment.refreshVisit);
                        self.$refs.DataRanking.SetDate(self.BeginDate, self.EndDate, self.CustomID,);
                        self.$refs.DataNewusermap.SetDate(self.BeginDate, self.EndDate, self.CustomID)
                    }
                };
                Http.Post(op);
            },

        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
        },
        components: {
            //组件
            DataEnrollment,
            //推广动态
       /*   DataList,*/
            //招生图
            DataRecruit,
            //百度地图
            DataNewusermap,
            //排行
            DataRanking,
        },
        beforeCreate: function () {
            //生命周期，创建前
          //  var self = this;
            //Account.GetCurrentUserInfo(function (CurrentUserInfo) {
            //    self.CustomerName_Show = CurrentUserInfo.CustomerName;
            //    self.SoftVersion = CurrentUserInfo.SoftVersion;
            //});
        },
        created: function () {
            //生命周期，创建完成
           //  var self = this;
            //Account.GetCurrentUserInfo(function (CurrentUserInfo) {
            //    self.CustomerName_Show = CurrentUserInfo.CustomerName;
            //    self.SoftVersion = CurrentUserInfo.SoftVersion;
            //});
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成
            var self = this;
            //Account.GetCurrentUserInfo(function (CurrentUserInfo) {
            //    self.CustomerName_Show = CurrentUserInfo.CustomerName;
            //    self.SoftVersion = CurrentUserInfo.SoftVersion;
            //});
            this.timer = setInterval(() => {
                self.date = self.GetDate(); // 修改日期数据
            }, 1000);
            self.Time = [
                moment(new Date(), "YYYY-MM-DD"),
                moment(new Date(), "YYYY-MM-DD"),
            ];
            self.BeginDate = util.formatDate(new Date());
            self.EndDate = util.formatDate(new Date());
            self.$refs.DataEnrollment.SetDate(self.BeginDate, self.EndDate, self.CustomID);
            self.$refs.DataNewusermap.SetDate(self.BeginDate, self.EndDate);
            self.$refs.DataRecruit.SetDate(self.BeginDate, self.EndDate, self.CustomID, self.$refs.DataEnrollment.refreshVisit);
           
            // self.deploys_mechanism();
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        },
    }
</script>
<style scoped>
    #index {
        background-color: #888;
    }

    #a-show-search {
        position: relative;
        left: 61%;
        top: -58px;
    }

    .dtlist > ul > li {
        display: flex;
        justify-content: space-around;
    }

        .dtlist > ul > li > a {
        }

    .full {
        position: absolute;
        right: 12px;
        top: 0px;
        height: 50px;
        line-height: 50px;
        color: #888;
    }

    .index-float {
        padding: 0 12px;
        text-align: right;
        margin-bottom: 12px;
    }

    .wall-bdl-title {
        margin-bottom: 14px;
        text-align: center;
        font-weight: 700;
    }
    .wall-bd{grid-template-columns: 75% 25%;}
    @media screen and (min-width:1650px) {
        .index-float {
            position: absolute;
            right: 0;
            top: 24px;
        }
    }
</style>
