<template>
    <div class="wall-bdr">
        <div>
            <div class="textal_c" style="padding:14px 0;position: relative;">
                <a-row>
                    <a-col :span="12">
                        <a-radio-group defaultValue="0" button-style="solid" @change="radioChange">
                            <a-radio-button value="0">
                                访问排行
                            </a-radio-button>
                            <a-radio-button value="1">
                                成交排行
                            </a-radio-button>
                        </a-radio-group>
                    </a-col>
                </a-row>
                <a class="getmore"><span @click="Member()">更多</span></a>
            </div>
            <div class="ranking-tlists" v-if="showList">
                <a-row type="flex" class="ranking-list" justify="start" align="middle" v-for="(item,index) in FansList.slice(0,7)" v-bind:key="item.ID">
                    <a-col flex="40px" class="textal_c ranking-tnumbe">{{index+1}}</a-col>
                    <a-col flex="48px" class="ranking-timg" @click="PartnerDetail(item)"><img :src="item.UHeadImg" /> </a-col>
                    <a-col flex="auto" class="ranking-ttitle">{{item.Name}}</a-col>
                    <a-col flex="80px" class="textal_c ranking-ttime">{{item.TargetCount}}</a-col>
                </a-row>
            </div>

            <div class="ranking-tlists" v-if="showList==false">
                <a-row type="flex" class="ranking-list" justify="start" align="middle" v-for="(item,index) in OrderList.slice(0,7)" v-bind:key="item.ID">
                    <a-col flex="40px" class="textal_c ranking-tnumbe">{{index+1}}</a-col>
                    <a-col flex="48px" class="ranking-timg"><img :src="item.UHeadImg" /> </a-col>
                    <a-col flex="auto" class="ranking-ttitle">{{item.Name}}</a-col>
                    <a-col flex="80px" class="textal_c ranking-ttime">{{item.TargetCount}}</a-col>
                </a-row>
            </div>
            <a-row type="flex" justify="space-between" align="bottom" style="padding:14px 10px;">
                <a-col flex="auto"><h3 style="font-weight:700;">素材排行</h3></a-col>
                <a-col flex="40px" @click="MaterialList()"><a href="../#/Ranking/MaterialRanking"><small>更多</small></a></a-col>
            </a-row>
        </div>
        <DataMaterial ref="DataMaterial" />
    </div>

</template>
<script>

    import DataMaterial from "../components/DataMaterial";
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "DataRanking",
        data() {
            return {
                OrderList: [],
                FansList: [],
                showList: true,
                BeginDate: util.formatDate(new Date()),
                EndDate: util.formatDate(new Date()),
                CustomID:"",
            };
        },
        components: {
            //组件
            DataMaterial,
        },
        props: {//组件属性
        },
        methods: {//方法
            loadData() {
                var self = this;
                console.log("排行" + self.CustomID);
                var op = {
                    url: "/OperationsModule/Operations3/GetRank",
                    data: {
                        BeginDate: self.BeginDate,
                        EndDate: self.EndDate,
                        CustomerID: self.CustomID,
                    },
                    OnSuccess: function (res) {
                        //console.log("res", res)
                        self.FansList = res.data.AgentItemPull;
                        self.OrderList = res.data.AgentItemDeal;
                    }
                };
                http.Post(op);
            },
            SetDate(BeginDate, EndDate, CustomID) {
                var self = this;
                self.BeginDate = BeginDate;
                self.EndDate = EndDate;
                self.CustomID = CustomID;
                self.loadData();
                self.$refs.DataMaterial.SetDate(self.BeginDate, self.EndDate, self.CustomID);
            },
            sortKey(array, key) {
                return array.sort(function (a, b) {
                    var x = a[key];
                    var y = b[key];
                    return ((x < y) ? 1 : (x > y) ? -1 : 0)
                })
            },
            radioChange: function (item) {
                var self = this;
                if (item.target.value == 0) {
                    self.showList = true;
                } else {
                    self.showList = false;
                }
            },
            PartnerDetail(e) {
                this.$router.push({
                    name: "PartnerList_PartnerDetail",
                    query: { ID: e.ID }
                });
            },
            //MaterialList() {
            //    var self = this;
            //    this.$router.push({
            //        name: "Material_MaterialList",
            //        query: { BeginDate: self.BeginDate, EndDate: self.EndDate }
            //    });
            //},
            Member: function () {
                var self = this;
                console.log("222" + self.CustomID);
                self.$router.push("../Ranking/MemberInformatio?CustomerID=" + this.CustomID);
            },
            handleChange(value) {
                //console.log(value)
                var self = this;
                self.BeginDate = util.formatDate(value[0]);
                self.EndDate = util.formatDate(value[1]);
                self.Time = value;
                self.loadData(self.BeginDate, self.EndDate);
                self.$refs.DataMaterial.SetDate(self.BeginDate, self.EndDate, self.CustomID);
            },
            radioGroupChange: function (item) {
                var self = this;
                var Time = "";
                switch (item.target.value) {
                    case "0":
                        Time = [
                            util.formatDate(new Date(), "YYYY-MM-DD"),
                            util.formatDate(new Date(), "YYYY-MM-DD"),
                        ];
                        break;
                    case "1":
                        Time = [
                            util.formatDate(new Date(), "YYYY-MM-DD"),
                            util.formatDate(new Date(), "YYYY-MM-DD"),
                        ];
                        break;
                    case "2":
                        Time = [
                            util.formatDate(
                                new Date(new Date().setDate(new Date().getDate() - 7)),
                                "YYYY-MM-DD"
                            ),
                            util.formatDate(new Date(), "YYYY-MM-DD"),
                        ];
                        break;
                    case "3":
                        Time = [
                            util.formatDate(
                                new Date(new Date().setDate(new Date().getDate() - 30)),
                                "YYYY-MM-DD"
                            ),
                            util.formatDate(new Date(), "YYYY-MM-DD"),
                        ];
                        break;
                    case "4":
                        var year = new Date().getFullYear();
                        Time = [
                            util.formatDate(new Date(util.formatDate(year + "-01-01")), "YYYY-MM-DD"),
                            util.formatDate(new Date(util.formatDate(year + "-06-30")), "YYYY-MM-DD"),
                        ];
                        break;
                }
                self.handleChange(Time);
            },
           
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },

        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            var self = this;
            var Time = [
                util.formatDate(new Date(), "YYYY-MM-DD"),
                util.formatDate(new Date(), "YYYY-MM-DD"),
            ];
            self.handleChange(Time);
            console.log(self.CustomID);
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>
<style>
    .flex-date {
        display: flex;
        justify-content: space-around;
        color: #fff;
        padding: 15px;
    }

        .flex-date > a {
            color: #fff;
        }

            a.active-a, .flex-date > a:hover {
                color: #2281f8;
                font-weight: bold;
            }

    .rank-num {
        font-style: italic;
        font-weight: bold;
        font-size: 18px;
        padding: 0 10px;
    }

    span.rank-num:first-child {
        color: #ffd800
    }

    span.rank-num:nth-child(2n+1) {
        color: #2281f8;
    }

    span.rank-num:nth-child(n+6) {
        color: #fff;
    }

    .getmore {
        position: absolute;
        right: 20px;
        top: 20px;
    }

    .ranking-tlists {
        width: 100%;
        height: 310px;
        overflow: hidden;
    }

    .ranking-list {
        height: 60px;
        line-height: 44px;
        flex-wrap: nowrap;
        overflow: hidden;
    }

        .ranking-list:nth-child(2n) {
            background-color: #fbfbfb;
        }

    .ranking-tnumbe {
        font-size: 24px;
        font-weight: 700;
    }

    .ranking-timg {
        position: relative;
    }

        .ranking-timg img {
            width: 36px;
            height: 36px;
            object-fit: cover;
            border-radius: 50%;
            overflow: hidden;
        }

    .ranking-ttitle {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #444;
    }

    .ranking-ttime {
        color: #999;
    }

    .ranking-list:nth-child(1) .ranking-tnumbe {
        color: #ff0e0e;
    }

    .ranking-list:nth-child(2) .ranking-tnumbe {
        color: #ffb812;
    }

    .ranking-list:nth-child(3) .ranking-tnumbe {
        color: #4eb2ff;
    }

    .ranking-list2 .ranking-timg img {
        width: 68px;
        height: 36px;
        border-radius: 4px;
    }

    .ranking-list2-title {
        line-height: 28px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .ranking-list2-text {
        font-size: 11px;
        line-height: 14px
    }

    .ranking-list:nth-child(1) .ranking-timg::after {
        content: "";
        position: absolute;
        top: -2px;
        right: 4px;
        width: 18px;
        height: 18px;
        background: url(../../../assets/imges/ranking1.svg) no-repeat center;
        background-size: cover;
        transform: rotate(40deg)
    }

    .ranking-list:nth-child(2) .ranking-timg::after {
        content: "";
        position: absolute;
        top: -2px;
        right: 4px;
        width: 18px;
        height: 18px;
        background: url(../../../assets/imges/ranking2.svg) no-repeat center;
        background-size: cover;
        transform: rotate(40deg)
    }

    .ranking-list:nth-child(3) .ranking-timg::after {
        content: "";
        position: absolute;
        top: -2px;
        right: 4px;
        width: 18px;
        height: 18px;
        background: url(../../../assets/imges/ranking3.svg) no-repeat center;
        background-size: cover;
        transform: rotate(40deg)
    }

    .ranking-list2 .ranking-timg::after {
        display: none;
    }
</style>