<template>

    <div class="wall-bdc-top">
        <h3 style="padding:10px 0;color:#232323">招生趋势图</h3>
        <div class="flex-item" style="visibility:hidden;height:0;width:0">
            <!--<a class="actt">昨天</a> <a>本周</a> <a>本月</a> <a>近1年</a>-->
            <div class="wall-datel">
                <a-radio-group defaultValue="0" @change="radioChange">

                </a-radio-group>
            </div>
        </div>
        <!--招生趋势图-->
        <div id="chartLineBox" style="width:100%;height:270px;  "> </div>

    </div>

</template>
<script>
    import http from "@/Plugin/Http.js";
    import echarts from 'echarts';
    import util from "@/Plugin/util.js";
    export default {
        name: "DataRecruit",
        data() {
            return {
                option: {
                    tooltip: { trigger: 'axis' },
                    legend: {
                        data: ['访问', '拉新', '意向', '咨询', '成交'],
                        textStyle: { color: '#000' }
                    },
                    color: ['#5B8FF9', '#000000', '#880015', '#ED1C24', '#58D4A4'],
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: ['2019-1-1', '2019-2-1', '2019-3-1', '2019-4-1', '2019-5-1', '2019-6-1', '2019-7-1',],
                        name: ' ',
                        nameTextStyle: { color: '#003A90', },
                        axisLine: { lineStyle: { color: '#CCD0D8', } }
                    },
                    //animationDelay: function (idx) {
                    //    // 越往后的数据延迟越大
                    //    return idx * 1000;
                    //},
                    //animation:1,
                    yAxis: {
                        name: '',
                        nameTextStyle: { color: '#CCD0D8', },
                        axisLine: { lineStyle: { color: '#CCD0D8', } },
                        type: 'value'
                    },
                    series: [
                        {
                            name: '访问',
                            data: [220, 232, 201, 234, 290, 230, 220],
                            type: 'line',
                            smooth: true,
                            lineStyle: { normal: { color: '#5B8FF9', } },
                        },
                        {
                            name: '拉新',
                            data: [220, 232, 201, 234, 290, 230, 220],
                            type: 'line',
                            smooth: true,
                            lineStyle: { normal: { color: '#000000', } },
                        },
                        {
                            name: '意向',
                            data: [220, 232, 201, 234, 290, 230, 220],
                            type: 'line',
                            smooth: true,
                            lineStyle: { normal: { color: '#880015', } },
                        },
                        {
                            name: '咨询',
                            data: [220, 232, 201, 234, 290, 230, 220],
                            type: 'line',
                            smooth: true,
                            lineStyle: { normal: { color: '#ED1C24', } },
                        },
                        {
                            name: '成交',
                            data: [120, 200, 150, 80, 70, 110, 130],
                            type: 'line',
                            smooth: true,
                            lineStyle: { normal: { color: '#58D4A4', } },
                        }
                    ]
                },
                BeginDate: util.formatDate(new Date()),
                EndDate: util.formatDate(new Date()),
                CustomID: "",
                type: 0,
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            getData: function (fn) {
                var self = this;
                var labels = [];
                var datas = [];
                var dataso = [];
                var datasn = [];
                var datasv = [];
                var datasi = [];
                var lineok = 0;
                var refreshline = function () {
                    if (lineok < 2)
                        return 0;
                    //console.log(labels, datas, dataso);
                    self.option.xAxis.data = labels;
                    self.option.series[0].data = datas;
                    self.option.series[1].data = datasn;
                    self.option.series[2].data = datasv;
                    self.option.series[3].data = datasi;
                    self.option.series[4].data = dataso;
                    self.chartLine.setOption(self.option);
                }
                console.log("访问记录" + self.CustomID);
                var op1 = {
                    url: "/OperationsModule/Operations3/OPNumberVisits", //访问记录

                    data: {
                        BeginDate: self.BeginDate,
                        EndDate: self.EndDate,
                        Type: self.type,
                        CustomerID: self.CustomID,
                    },
                    OnSuccess: function (hdata) {
                        var data = hdata.data;
                        var vcount = 0;
                        var daylength = self.getDaysBetween(self.BeginDate, self.EndDate);
                        if (daylength) { //间隔大于0天
       
                            var startdate = Date.parse(self.BeginDate);
                            for (var i = 0; i <= daylength; i++) {
                                var dat = new Date(startdate.valueOf());
                                dat.setDate(dat.getDate() + i);
                                var key = util.formatDate(dat);

                                datas.push(0);

                                data.forEach(e => {
                                    if (key == e.DateName) {   //命中日期
                                        datas[i]=e.QRcodeRecordCount;
                                        vcount += e.QRcodeRecordCount;
                                        return false;
                                    }
                                });

                                var key2 = key.substr(5);
                                labels.push(key2);
                                console.log("展示数据", datas, labels)
                            }
                        } else { //同一天
                            data.forEach(e => {
                                labels.push(e.DateName);
                                datas.push(e.QRcodeRecordCount);
                                vcount += e.QRcodeRecordCount;
                            });
                        }
                        lineok += 1;
                        fn && fn(vcount);
                        refreshline();
                    }
                };
                http.Post(op1);

                var op2 = {
                    url: "/OperationsModule/Operations3/OPNumberTransactions", //访问记录
                    data: {
                        BeginDate: self.BeginDate,
                        EndDate: self.EndDate,
                        Type: self.type,
                        CustomerID: self.CustomID,
                    },
                    OnSuccess: function (hdata) {
                        var data = hdata.data;
                        var daylength = self.getDaysBetween(self.BeginDate, self.EndDate);
                        if (daylength) { //间隔大于0天
                            var startdate = Date.parse(self.BeginDate);
                            for (var i = 0; i <= daylength; i++) {
                                var dat = new Date(startdate.valueOf());
                                dat.setDate(dat.getDate() + i);
                                var key = util.formatDate(dat);

                                dataso.push(0);
                                datasn.push(0);
                                datasv.push(0);
                                datasi.push(0);

                                data.forEach(e => {
                                    if (key == e.DateName) {   //命中日期
                                        dataso[i](e.PayOrderCount);
                                        datasn[i](e.NewCount);
                                        datasv[i](e.IntentionCount);
                                        datasi[i](e.ConsultCount);
                                        return false;
                                    }
                                });

                                // var key2 = key.substr(5);
                                //labels.push(key2);
                               
                            }
                        } else { //同一天
                            data.forEach(e => {
                                dataso.push(e.PayOrderCount);
                                datasn.push(e.NewCount);
                                datasv.push(e.IntentionCount);
                                datasi.push(e.ConsultCount);
                            });
                        }
                        lineok += 1;
                        refreshline();
                    }
                };
                http.Post(op2);

            },
            SetDate(BeginDate, EndDate, CustomID, fn) {
                //console.log("begin", BeginDate, "end", EndDate);
                var self = this;
                self.BeginDate = BeginDate;
                self.EndDate = EndDate;
                self.CustomID = CustomID;
                //调用getData方法（）
                self.getData(fn);
            },
            getDaysBetween: function (dateString1, dateString2) { //计算间隔天数
                var startDate = Date.parse(dateString1);
                var endDate = Date.parse(dateString2);
                var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
                return days;
            },
            radioChange: function (item) {
                var self = this;
                var Time = "";
                switch (item.target.value) {
                    case "0":
                        self.type = 0,
                            Time = [
                                util.formatDate(
                                    new Date(new Date().setDate(new Date().getDate())),
                                    "YYYY-MM-DD"
                                ),
                                util.formatDate(
                                    new Date(new Date().setDate(new Date().getDate())),
                                    "YYYY-MM-DD"
                                ),
                            ];
                        break;
                    case "1":
                        self.type = 1,
                            Time = [
                                util.formatDate(
                                    new Date(new Date().setDate(new Date().getDate() - 7)),
                                    "YYYY-MM-DD"
                                ),
                                util.formatDate(new Date(), "YYYY-MM-DD"),
                            ];
                        break;
                    case "2":
                        self.type = 2,
                            Time = [
                                util.formatDate(
                                    new Date(new Date().setDate(new Date().getDate() - 30)),
                                    "YYYY-MM-DD"
                                ),
                                util.formatDate(new Date(), "YYYY-MM-DD"),
                            ];
                        break;
                    case "3":
                        self.type = 3;
                        //var year = new Date().getFullYear();
                        Time = [
                            util.formatDate(new Date(util.formatDate(getLastYearYestdy(new Date()))), "YYYY-MM-DD"),
                            util.formatDate(new Date(util.formatDate(new Date())), "YYYY-MM-DD"),

                        ];
                        break;
                }
                self.handleChange(Time);
            },
            handleChange(value) {
                //console.log(value)
                var self = this;
   /*             console.log(value[0]._d)*/
                self.BeginDate = util.formatDate(value[0]);
                self.EndDate = util.formatDate(value[1]);
                self.Time = value;

                self.SetDate(self.BeginDate, self.EndDate);
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件

        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            var self = this;
            self.chartLine = echarts.init(document.getElementById('chartLineBox'));
         document.getElementById('chartLineBox').offsetWidth
           
            // self.getData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
    function getLastYearYestdy(date) {
        var datastr = "";
        var strYear = date.getFullYear() - 1;
        var strDay = date.getDate();
        var strMonth = date.getMonth() + 1;
        if (strMonth < 10) {
            strMonth = "0" + strMonth;
        }
        if (strDay < 10) {
            strDay = "0" + strDay;
        }
        datastr = strYear + "-" + strMonth + "-" + strDay;
        return datastr;
    }
</script>
<style scoped>
    #chartLineBox > div > canvas {
        width: 100% !important;
    }

    .flex-item {
        display: flex;
        justify-content: space-around;
        width: 50%;
        margin: 20px auto;
    }

        .flex-item > a {
            color: #fff;
            border: 1px solid #0056ce;
            flex: 1;
            text-align: center;
            padding: 5px 0;
        }

            .flex-item > a:hover, a.actt {
                color: #2281f8;
            }
</style>