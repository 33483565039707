<template>
    <div>
        <div class="wall-data">
            <div class="wall-data-text">
                <span @click="PlatformDetails()">{{NumberPlatforms}}</span>
                <p>平台总数</p>
            </div>
            <div class="wall-data-text">
                <span>{{TimeNumberPlatforms}}</span>
                <p>平台注册</p>
            </div>
            <div class="wall-data-text">
                <span @click="VisitRanking()">{{VistingCount}}</span>
                <p>访问</p>
            </div>
            <div>
                <span @click="VisitRanking()">{{d_pullnew}}</span>
                <p>拉新</p>
            </div>
            <div class="wall-data-text">
                <span @click="IntentionConsultation()">{{d_intention}}</span>
                <p>意向</p>
            </div>
            <div class="wall-data-text">
                <span @click="IntentionConsultation()">{{d_consult}}</span>
                <p>咨询</p>
            </div>
            <div class="wall-data-text">
                <span @click="IntentionConsultation()">{{d_deal}}</span>
                <p>成交</p>
            </div>
        </div>
    </div>
</template>
<style>
    .wall-data {
        height: 90px;
        width: 100%;
        font-size: 40px;
        /* margin-left: 5%; */
        grid-template-columns:repeat(7, 1fr);
    }

        .wall-data > div {
            font-size: 40px
        }
</style>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "DataEnrollment",
        data() {
            return {
                NumberPlatforms: 0,
                TimeNumberPlatforms: 0,
                VistingCount: 0,
                d_pullnew: 0,
                d_intention: 0,
                d_consult: 0,
                d_deal: 0,
                BeginDate: new Date(),
                EndDate: new Date(),

            };
        },
        props: { //组件属性
        },
        methods: { //方法
            ///平台总数量
            Number: function () {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations3/OPNumberPlatforms",
                    OnSuccess: function (data) {
                        self.NumberPlatforms = data.data;
                    }
                };
                http.Post(op);
            },
            ///时间范围内平台注册的数量
            NumberTime: function () {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations3/OPNumberPlatformsTime",
                    data: {
                        BeginDate: self.BeginDate,
                        EndDate: self.EndDate,
                    },
                    OnSuccess: function (data) {
                        var dt = data.data;
                        if (self.CustomID != "") {
                            dt.NumberPlatforms = 0;
                            self.anmshow(dt.NumberPlatforms, 8, "TimeNumberPlatforms");
                        } else {
                            self.anmshow(dt.NumberPlatforms, 8, "TimeNumberPlatforms");
                        }

                    }
                };
                http.Post(op);
            },
            ///拉新、意向、提交等数据
            getData: function () {
                var self = this;
                self.d_pullnew =
                    self.d_intention =
                    self.d_consult =
                    self.VistingCount =
                    self.d_deal = "-";
                self.VistingCount = 0;
                var op = {
                    url: "/OperationsModule/Operations3/EnrollmentInformation",
                    data: {
                        BeginDate: self.BeginDate,
                        EndDate: self.EndDate,
                        CustomerID: self.CustomID,

                    },
                    OnSuccess: function (hdata) {
                        var data = hdata.data;
                        self.anmshow(data.NewCount, 8, "d_pullnew");
                        self.anmshow(data.VisitCount, 8, "d_intention");
                        self.anmshow(data.IntentionCount, 8, "d_consult");
                        self.anmshow(data.DealCount, 8, "d_deal");
                        //console.log("拉新、意向、提交等数据");
                        //console.log(data);
                    }
                };
                http.Post(op);
            },
            refreshVisit: function (vcount) {
                var self = this;
                self.anmshow(vcount, 8, "VistingCount");
            },
            anmshow: function (v, l, o) {
                var self = this;
                if (!v) {
                    self[o] = v;
                    return;
                }
                if (v < l)
                    l = v;
                var c = parseInt(v / l) || 1;
                (function loop(no, lc, sum, fd) { //显示数据,循环次数,当前累计值,字段
                    sum += lc;
                    sum < no && (setTimeout(loop, 100, no, lc, sum, fd), self[o] = sum, 1) || (self[o] = no, 1);
                })(v, c, 0, o);
            },
            SetDate(BeginDate, EndDate, CustomID) {
                var self = this;
                self.BeginDate = BeginDate;
                self.EndDate = EndDate;
                self.CustomID = CustomID;
                self.Number();
                //if (!String.IsNullOrEmpty(CustomID)) {
                //    self.TimeNumberPlatforms = 0;
                //} else {
                //    self.NumberTime();
                //}
                self.NumberTime();
                self.getData();

            },
            VisitRanking() {
                var self = this;
                self.$router.push("../Ranking/VisitRanking")
                //self.$router.push({
                //    name: "Ranking_VisitRanking",
                //    query: {
                //        BeginDate: util.TimeStamptoDateTime("/Date(" + new Date(self.BeginDate).getTime() + ")/", 'yyyy-MM-dd'),
                //        EndDate: util.TimeStamptoDateTime("/Date(" + new Date(self.EndDate).getTime() + ")/", 'yyyy-MM-dd'),
                //    }
                //})
            },
            IntentionConsultation() {
                var self = this;
                self.$router.push("../Ranking/IntentionConsultation")

            },
            PlatformDetails() {
                var self = this;
                self.$router.push("../Ranking/PlatformDetails")
            },
            AgentGuestLogList(e) {
                var self = this;
                self.$router.push({
                    name: "Operations_AgentGuestLogList",
                    query: {
                        Category: e,
                        BeginDate: util.TimeStamptoDateTime("/Date(" + new Date(self.BeginDate).getTime() + ")/", 'yyyy-MM-dd'),
                        EndDate: util.TimeStamptoDateTime("/Date(" + new Date(self.EndDate).getTime() + ")/", 'yyyy-MM-dd'),
                    }
                })
            },
            OrderList() {
                this.$router.push({
                    name: "Order_OrderList"
                })
            }
        },
        computed: { //计算属性
        },
        watch: { //侦听属性
        },
        components: { //组件
        },
        beforeCreate: function () { //生命周期，创建前
        },
        created: function () { //生命周期，创建完成
        },
        beforeMount: function () { //生命周期，组装前
        },
        mounted: function () { //生命周期，组装完成
            //this.getData();
        },
        beforeUpdate: function () { //生命周期，data更新前
        },
        updated: function () { //生命周期，data更新完成
        },
        beforeDestroy: function () { //生命周期，销毁前
        },
        destroyed: function () { //生命周期，销毁完成
        }
    };
</script>
