<template>
    <div>
        <div class="wall-bdl-top">
            <div class="ranking-tlists">
                <a-row type="flex" class="ranking-list ranking-list2" justify="start" v-for="(item,index) in Material.slice(0,5)" v-bind:key="item.MaterialID">
                    <a-col flex="40px" class="textal_c ranking-tnumbe">{{index+1}}</a-col>
                    <a-col flex="74px" class="ranking-timg"><img :src="Getsrc(item)" /> </a-col>
                    <a-col flex="auto" class="ranking-ttitle">
                        <div class="ranking-list2-title" @click="ShowDetail(item)">{{item.Name}}</div>
                        <div class="ranking-list2-text">
                            <a-space size="large">
                                <div @click="ReadCount(item)">访问：{{item.ReadCount}}</div>
                                <div @click="PullNewCount(item)">拉新：{{item.NewReadCount}}</div>
                            </a-space>

                        </div>
                    </a-col>

                </a-row>
            </div>
        </div>
        <div v-if="DetailVisible">
            <Detail :closeDetail="closeDetail" :MaterialType="MaterialType" :ID="ID" :PushID="PushID" :IDType=0 :disabled="disabled"></Detail>
        </div>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    import Detail from '@/Views/MaterialModule/M2Material/Detail';
    export default {
        name: "DataMaterial",
        data() {
            return {
                Material: [],
                DetailVisible: false,
                MaterialType: -1,
                ID: "",
                disabled: false,

                BeginDate: util.formatDate(new Date()),
                EndDate: util.formatDate(new Date()),
                CustomID:"",
            };
        },
        props: {//组件属性

        },
        methods: {//方法
            loadData() {
                var self = this;

                var op = {
                    url: "/OperationsModule/Operations3/HotMaterial",
                    data: {
                        BeginDate: self.BeginDate,
                        EndDate: self.EndDate,
                        CustomerID: self.CustomID,
                    },
                    OnSuccess: function (res) {

                        self.Material = res.data;
                        //console.log("data", res.data)
                        //console.log("Material", self.Material)
                    }
                };
                http.Post(op);
            },
            SetDate(BeginDate, EndDate, CustomID) {
                var self = this;
                self.BeginDate = BeginDate;
                self.EndDate = EndDate;
                self.CustomID = CustomID;
                self.loadData();
            },
            NewMaterial_List() {
                this.$router.push({
                    name: "NewMaterial_List"
                })
            },
            closeDetail(e) {
                var self = this;
                if (e) {
                    // self.loadData();
                }
                self.DetailVisible = false;
            },
            Getsrc: function (item) {
                var rt = item.Logo;// item.ImgTopic == null ? item.Logo : item.ImgTopic;
                if (rt == null) {
                    rt = "#"
                } else {
                    if (rt.indexOf('upload2') < 0) {
                        rt = rt.replace("upload", "upload2");
                    }
                }
                return rt;
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
            Detail
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成

        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>
<style scoped>
.ranking-list2-title{}
</style>